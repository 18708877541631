module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9RNCFNFM2R"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/@imgix/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"defaultImgixParams":{"auto":["format","compress"]},"disableIxlibParam":true,"fields":[{"nodeType":"MicrocmsBlogImage","fieldName":"imgixImage","rawURLKey":"url"},{"nodeType":"MicrocmsPageImage","fieldName":"imgixImage","rawURLKey":"url"},{"nodeType":"MicrocmsPicturePicture","fieldName":"imgixImage","rawURLKey":"url"},{"nodeType":"MicrocmsBlogGalleriesGalleryImagesImage","fieldName":"imgixImage","rawURLKey":"url"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92575e2002f6ef1d9d94463bf3a59201"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
